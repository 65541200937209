.data-main-inner {
    background: #fff !important;
    padding: 15px !important;
    border-radius: 10px !important;
    box-shadow: 0 2px 5px 0 #372599 !important;
    /* transform: rotate(-1deg) !important; */
    /* box-shadow: 0 2px 48px 0 #00000014 !important; */
}

.data-second-data-integra p {
    font-size: 18px;
}

.data-third-sec p {
    font-size: 18px;
}

.data-slide-integra {
    content: '';
    width: 1px !important;
    height: 100px;
    border: 1px solid black;
    position: relative;
    top: 6px;
}