.footer-main-sec{
    background: #372599;
    color: #fff;
}
.footer-detail{
    display: flex;
    align-items: start;
    justify-content: start;
    text-align: start;
}
.footer-cotac{
    text-decoration: none !important;
    color: #fff;
}
/* 
.facebook-footer{
    color: #fff;
    font-size: 1.8rem !important;
}

.twitter-footer{
    color: #fff;
    font-size: 1.5rem !important;
}
.youtube-footer{
    color: #fff;
    font-size: 2.3rem !important;
} */

.form-soci-icon-footer{
    border: 2px solid #fff;
    padding: 0.25rem;
    border-radius: 49%;
    color: #fff;
    width: 100%;
    justify-content: center;
    align-items: center;
}