.nav-tabs {
    border-bottom: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}


.nav-tabs .nav-link {
    background: #E1EFFF;
    font-size: 1.1rem !important;
    font-weight: 600 !important;
    border-radius: 10px !important;


}

.nav-link.active {
    background: #372599 !important;
    color: #fff !important;
    border: none !important;
    box-shadow: 0 2px 48px 0 #00000014;
}

.price-data-head {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

}
.price-data-head .nav-item button{
width: 6rem !important;
}
.ta-he-pri{
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}
td{
    font-size: 16px; 
}
.price-inner-1{
    font-size: 20px;
    margin-bottom: 0px !important;
    font-weight: 600;
}
.ta-hea-2{
    position: sticky;
    top: 5rem !important;
}

.check-ico-price{
    color: green;
}
.close-ico-price{
    color: red;
}
.price-inne-2 {
    width: 200px !important;
    padding: 3px 5px !important;

}

.custom-accordion-header .accordion-button {
    background-color: #fff !important;
    color: #000;
    padding: 5px 10px !important;
}

.custom-accordion-title {
    color: #000;
    font-size: 17px !important;
    margin-bottom: 0px !important;
}
.price-bt{
    background: #372599 !important;
    border-radius: 10px !important;
    border: none !important;
}

.custom-accordion-body{
    padding: 0px 5px !important;
}

/* faqs */

.faq-price-acc-header button:not(.collapsed){
    background: none !important;
    color: #000 !important;
}
.faq-price-acc-item{
    border: 0px !important;
    margin: 1rem 0px !important;
}

.faq-price-acc .accordion-button::after {
    content: none;  /* Removes the default icon (caret) */
}
.faq-price-acc .accordion-button:not(.collapsed)::before {
    content: '-';  /* Show plus sign when collapsed */
    font-size: 1.5rem !important; /* Adjust size as needed */
    
    margin-left: 10px; /* Space between the text and the icon */
}

.faq-price-acc .accordion-button.collapsed::before {
    content: '+';  /* Show minus sign when expanded */
    font-size: 1.5rem !important; /* Adjust size as needed */
   
    margin-left: 10px; /* Space between the text and the icon */
}

/* Optional: Customize the button when expanded or collapsed */
.faq-price-acc .accordion-button {
    padding-right: 30px; 
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.faq-price-acc-body{
    font-size: 19px !important;
}
.faq-price-acc-header h5{
    font-weight: 400;
}

