.data-main-analytics-inner {
    background: #fff !important;
    padding: 15px !important;
    border-radius: 10px !important;
    box-shadow: 0 2px 5px 0 #372599 !important;
    /* transform: rotate(-1deg) !important; */
    /* box-shadow: 0 2px 48px 0 #00000014 !important; */
}
.new-sli-analytics{
    content: ' ' !important;
    width: 8px !important;
    height: 100px !important;
    background: #000 !important;
}
.new-sli-analytics-2{
    content: ' ' !important;
    width: 17px !important;
    height: 100px !important;
    background: #000 !important;
}

.data-analaytics-slide-new{
    content: '';
    width: 1px;
    height: 100px;
    border: 1px solid #000;   
}
.accordion-button:not(.collapsed) {
    /* background-color: #9d8ef3c9 !important; */
    background: #fff!important;
    color: #000 !important; 
    box-shadow: none !important ;
}
.accordion-button:focus{
    box-shadow: none !important;
    border: none !important;
}
.accordion-button{
    padding: 10px 1rem 10px 1rem !important;
}
.head-fot-di{
    font-family: 'Bartomes', sans-serif !important;
   
    font-weight: 600 !important;
}
.acc-eac-it{
    border: 1px solid #372599 !important;
   
}
.acc-eac-it p{
    margin-bottom: 0px !important;
}