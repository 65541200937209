/* .custom-carousel-container {
    width: 80%;
    margin: auto;
    overflow: hidden;
}

.custom-carousel .carousel-inner {
    display: flex;
}

.custom-carousel-item {
    display: flex;
    align-items: center;
}

.custom-carousel-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.custom-carousel-image {
    width: 50%;
    height: auto;
    object-fit: cover;
}

.custom-carousel-text {
    width: 50%;
    padding: 20px;
}

.custom-carousel-text h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.custom-carousel-text p {
    font-size: 16px;
    color: #555;
}

.carousel-control-prev,
.carousel-control-next {
    color: black;
    font-size: 30px;
}

.carousel-indicators {
    display: none;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background: #372599;
    
}
.imirai-car-img{
    width: 50%;
    border: 1px solid #372599;
    border-radius: 10px;
} */

.imir-car{
    box-shadow: 0 2px 5px 0 #372599 !important;
    border-radius: 10px;
    padding: 10px;
    height: 188px;
    margin-bottom: 10px;
}
.imirai-main-inner{
    background: #fff !important;
    padding: 15px !important;
    border-radius: 10px !important;
    box-shadow: 0 2px 5px 0 #372599 !important;
}
.head-fot-imirai{
    font-family: 'Bartomes', sans-serif !important;
   
    font-weight: 700 !important;
    /* font-size: 1rem; */
}