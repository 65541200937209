.new-home-page-tool-design {

    /* background: linear-gradient(rgb(0 8 24 / 86%), rgb(78 89 110 / 84%)), url('../assets/home/SmartIG-WebsiteBannaer.jpg'); */
    /* background: url('../assets/home/SmartIG-WebsiteBannaer2.png'); */
    background: linear-gradient(to right,rgba(91,72,161,255) , rgb(150,135,190));
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh !important;
    /* padding: 3rem 0rem; */
}
.head-of-new-toll {
    /* display: flex !important; */
    /* align-items: center !important; */
    /* justify-content: center !important; */
    /* flex-direction: column !important; */
    color: #fff;
}
.btn-new-ho {
    border-radius: 50px !important;
    background: #372599 !important;
    color: #fff;
    border: none !important;
}
.home-slid-bar-new {
    content: '';
    width: 1px;
    height: 100px;
    border: 1px solid black;
    position: relative;
    top: 7px;
}
.cal-inner {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0 #372599 !important;
    /* transform: rotate(-1deg); */
    /* box-shadow: 0 2px 48px 0 #00000014; */
    /* background: lightgoldenrodyellow; */
}
.new-ro-fra-1 {
    /* background: #372599; */
    color: #fff;
    border-radius: 10px;
    border: 2px solid #372599;
    /* background: #372599; */
    color: #000;
    /* box-shadow: 0 2px 5px 0 #372599 !important; */
}
.new-ro-fra-2 {
    box-shadow: 0 2px 48px 0 #00000014;
    border-radius: 10px;
}