.head-main{
    background: #fff;
    padding: 1rem 3rem 1rem 3rem !important;
    box-shadow: 0 2px 48px 0 #00000014;
}
.head-main-image-sec{
    width: 350px !important;
}
.header-butto{
    font-size: 16px !important;     
    color: black !important;
}
@media (max-width: 1364px) and (min-width:964px) {
    .head-main-image-sec{
        width: 350px !important;
    }
    .header-butto{
        font-size: 14px !important;
    }
    .head-main{
        padding: 1rem 1rem 1rem 1rem !important;
    }
}
