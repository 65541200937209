.cilent-ico-sec{
    display: flex !important;
    align-items: center;
    justify-content: center ;
}
.seamles-cilent-imag-1{
    background: #fff !important;
    border-radius: 10px !important;
    box-shadow: 0 2px 8px 0 #372599 !important;
}

.support-files-icoo{
    display: flex !important;
    align-items: center;
    justify-content: center ;
}
.file-img-sec,
.image-vide-img-se,
.social-meida-img-sec{
    display: flex !important;
    align-items: center;
    justify-content: center ;
}
.data-third-sec p{
    margin: 0px !important;
}